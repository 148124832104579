.projects-page {
  overflow: hidden;
}

.projects-banner-container {
  width: 90%;
  margin: auto;
  display: flex;
}

@media screen and (max-width: 800px) {
  .projects-banner-container {
    flex-direction: column;
  }
}

.projects-banner {
  width: 70%;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .projects-banner {
    width: 90%;
  }
}

.projects-banner h1 {
  color: var(--primary-highlight);
  font-size: 3.5rem;
  margin-bottom: 0;
}

.projects-banner p {
  margin-left: 0;
}

.projects-button-container {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .projects-button-container {
    height: auto;
  }
}

.projects-container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 30px;
}

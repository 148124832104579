.project-container {
  width: 24%;
  min-width: 200px;
  display: flex;
  margin: 5px;
  flex-direction: column;
  align-items: center;
  background-color: var(--sidebar-background);
}

@media screen and (max-width: 450px) {
  .project-container {
    width: 90%;
    min-height: 200px;
  }
}

.project-container h3 {
  color: var(--secondary-highlight);
  font-size: larger;
  height: 20px;
}

.project-links-container {
  width: 80%;
  display: flex;
  justify-content: center;
}
.project-links-container .project-link {
  text-decoration: none;
  color: var(--primary-highlight);
  margin: 5px;
}

.project-links-container .project-link:hover {
  color: var(--secondary-highlight);
}

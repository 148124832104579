.contact-component {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #999;
}

.contact-form-container {
  width: 45%;
}

.contact-form-container h1 {
  color: var(--primary-highlight);
  font-size: 4.5rem;
}

.contact-form-container p {
  margin-left: 0;
}

.contact-form-container form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.contact-form-container form input {
  font-size: 1.1rem;
  color: #999;
}

.form-row-one {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-row-one input {
  background-color: var(--dark-grey);
  border: none;
  height: 50px;
  margin-block: 5px;
  width: 47%;
}

.form-row-one input::placeholder {
  padding-left: 10px;
  font-size: 1.1rem;
}

@media screen and (max-width: 450px) {
  .form-row-one {
    flex-direction: column;
  }
  .form-row-one input {
    width: 99%;
  }
  form {
    padding-bottom: 1rem;
  }
}

form .form-row-two {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.form-row-two input,
textarea {
  background-color: var(--dark-grey);
  border: none;
  height: 50px;
  margin-block: 5px;
}

.form-row-two input::placeholder,
textarea::placeholder {
  padding-left: 10px;
  font-size: 1.1rem;
}

.form-row-two .message-input {
  height: 150px;
  padding-top: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  color: #999;
}

input:focus,
textarea:focus {
  outline: none;
  border-bottom: 4px solid var(--primary-highlight);
}

.contact-logo-container {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .contact-logo-container {
    display: none;
  }

  .contact-form-container {
    width: 90%;
  }
}

.contact-logo-container svg {
  width: 100%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 125px;
  height: 100%;
  color: var(--grey);
  background-color: var(--sidebar-background);
}

.sidebar .sidebar-banner {
  height: 250px;
  background-color: black;
}

.sidebar-banner img {
  width: 80%;
  border-radius: 5px;
  margin-left: 8%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.sidebar-banner div {
  text-align: center;
  cursor: default;
}

.sidebar-banner div:hover {
  color: var(--primary-highlight);
}

.sidebar-links {
  text-align: center;
  font-family: cursive;
  font-size: 90%;
  height: 40px;
  border-bottom: 1px solid var(--grey);
}

.sidebar-links .sidebar-navlink {
  color: var(--grey);
  text-decoration: none;
}

.sidebar-links .sidebar-navlink:hover {
  color: var(--primary-highlight);
}

.sidebar-social-links {
  height: 100px;
  display: flex;
  justify-content: center;
}

.sidebar-social-links .sidebar-navlink {
  width: 62.5px;
  text-align: center;
  margin-top: 10px;
  color: var(--grey);
  text-decoration: none;
  cursor: pointer;
}

.sidebar-social-links .sidebar-navlink:hover {
  color: var(--primary-highlight);
}

.sidebar-social-links svg {
  padding-top: 10px;
  height: 25px;
  width: 25px;
  fill: var(--grey);
}

.sidebar-social-links svg:hover {
  fill: var(--primary-highlight);
}

@media screen and (max-width: 800px) {
  .sidebar {
    width: 100vw;
    z-index: 100;
    transition: transform 0.3s ease-out;
  }
  .sidebar .sidebar-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sidebar-banner img {
    width: 100px;
  }
  .open {
    transform: translateX(0);
  }
  .closed {
    transform: translateX(-100%);
  }
}

.blog-container {
  width: 35%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: var(--dark-grey);
}

@media screen and (max-width: 800px) {
  .blog-container {
    width: 85%;
  }
}

.blog-container h2 {
  color: var(--secondary-highlight);
  margin-bottom: 5px;
}

.blog-container p {
  margin-left: 0;
  margin-top: 0;
}

.blog-links-container {
  width: 80%;
  display: flex;
}
.blog-links-container .blog-link {
  text-decoration: none;
  color: var(--primary-highlight);
}

.blog-links-container .blog-link:hover {
  color: var(--secondary-highlight);
}

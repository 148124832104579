@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap");

:root {
  --primary-background: #1d1d1d;
  --primary-highlight: #08fdd8;
  --secondary-highlight: #fd2155;
  --grey: #8d8d8d;
  --dark-grey: #2b2b2b;
  --sidebar-background: #181818;
}

body {
  background-color: var(--primary-background);
  color: white;
}

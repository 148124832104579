.header-container {
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}

header {
  margin-top: 30vh;
  margin-left: 80px;
}

header h1 {
  font-size: 5.5rem;
  line-height: 2rem;
  font-family: cursive;
}

@media screen and (max-width: 1100px) {
  header h1 {
    font-size: 3.5rem;
  }
  header {
    margin-left: 40px;
  }
}

@media screen and (max-width: 750px) {
  header h1 {
    font-size: 2.5rem;
  }
  header {
    margin-left: 20px;
  }
}

header p {
  color: var(--grey);
  margin: 0;
  font-size: 1.2rem;
}

header span {
  color: var(--primary-highlight);
}

.header-button {
  cursor: pointer;
  height: 50px;
  margin-top: 30px;
  font-size: larger;
  background-color: transparent;
  border: 1px solid var(--primary-highlight);
  color: var(--primary-highlight);
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.header-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-highlight);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.header-button:hover,
.header-button:focus,
.header-button:active {
  color: white;
}
.header-button:hover:before,
.header-button:focus:before,
.header-button:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

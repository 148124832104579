.skills-container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .skills-container {
    flex-direction: column;
    margin: auto;
  }
}

.skills-container .left-container {
  margin: auto;
  width: 50%;
  margin-left: 40px;
}

@media screen and (max-width: 1200px) {
  .skills-container .left-container {
    margin: auto;

    margin-top: 100px;
    width: 80%;
  }
}

.left-container h1 {
  font-size: 3.5rem;
  color: var(--primary-highlight);
}

.left-container p {
  font-size: larger;
  margin-left: 0;
}

.left-container a {
  text-decoration: none;
  color: var(--primary-highlight);
}

.skills-container .right-container {
  margin: auto;
  margin-top: 15vh;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1200px) {
  .skills-container .right-container {
    max-width: 90%;
  }
}

.right-container .logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logos-container svg {
  width: 40px;
  height: 40px;
  margin: 15px;
  margin-top: 25px;
}

.right-container .experience-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.experience-container div {
  background-color: var(--dark-grey);
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 30px;
  margin-right: 4%;
}

@media screen and (max-width: 800px) {
  .experience-container div {
    width: 90%;
  }
}

.experience-container div h3,
h4,
p {
  margin: 20px;
}
.experience-container div h4 {
  height: 2px;
}

.experience-container div .experience-date {
  font-size: 70%;
}

.experience-container div .experience-details {
  margin-top: 35px;
}

.content {
  margin-left: 125px;
}

.hamburger {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
}

@media screen and (max-width: 800px) {
  .content {
    margin-left: 0;
  }
  .hamburger {
    display: block;
    z-index: 200;
  }
}

.blogs-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.blogs-heading-container {
  margin-left: 2em;
}

.blogs-heading-container .blogs-heading h1 {
  color: var(--primary-highlight);
  font-size: 2.5rem;
  margin-bottom: 0;
  margin-top: 60px;
}

.blogs-heading-container .blogs-heading p {
  margin-left: 0;
}

.blogs-component .blogs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

@media screen and (max-width: 800px) {
  .blogs-component .blogs-container {
    flex-direction: column;
  }
}
